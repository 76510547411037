















































import { PageBase } from "@/core/models/shared";
import { AlunoService } from "@/core/services/geral";
import { Component } from "vue-property-decorator";

@Component
export default class RelatorioChamada extends PageBase{
    service: AlunoService = new AlunoService();
    lista: any[] = [];
    filtro = {
        inicio: '',
        fim: ''
    }
    overlay: boolean = false;
    
    mounted() {

        this.filtro.inicio = this.$route.query.inicio ? this.$route.query.inicio.toString() : '';
        this.filtro.fim = this.$route.query.fim ? this.$route.query.fim.toString() : '';

        this.overlay = true;

        this.service.Aniversariantes(this.filtro.inicio, this.filtro.fim).then(
            res =>{
                this.lista = res.data;
            },
            err => {
                if (!err.response){
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");
                }
                else{
                    this.$swal("Aviso",err.response.data,"error")
                }
            }
        ).finally(() => {
            this.overlay = false;
        })
    }
}
